@keyframes fade{
    from {
        opacity: 0;
    }
    to {
        opacity: 1;
    }
}

@keyframes slide-down {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateY(0%);
    }
}

@keyframes slide-right{
    from {
        transform: translateX(-100%);
    }
    to {
        transform: translateX(0%);
    }
}
