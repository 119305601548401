@tailwind base;
@tailwind components;
@tailwind utilities;
@import "animations-simple";

* {
  margin: 0;
  padding: 0;
}


html,
body {
  height: 100%;
  font-size: 16px;
  //   background-color: #dddddd;
}



html::-webkit-scrollbar {

  width: 8px;
  height: 16px;
}

html::-webkit-scrollbar-thumb {

  background-color: #888;
  border-radius: 4px;


}

html::-webkit-scrollbar-thumb:hover {

  background-color: #555;
}

body {
  margin: 0;
  font-family: "SF Pro Display", -apple-system, BlinkMacSystemFont, "SF Pro Text", "Helvetica Neue", "Helvetica", "Arial", sans-serif;


}

a {
  text-decoration: none;
  color: unset;
}

.lh-normal {
  line-height: normal !important;
}

.flex-column {
  display: flex !important;
  flex-direction: column !important;
}

.flex-center {
  justify-content: center !important;
  align-items: center !important;
}

.view-container {
  min-height: 100vh !important;
  min-width: 100vw !important;
  display: flex !important;
  flex-direction: column !important;
  justify-content: center !important;
  align-items: center !important;
}

.outline {
  border: 1px solid red !important;
}

.rotate-90 {
  transform: rotate(-90deg);
}

.center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.spacer {
  flex: 1;
}

.anim-fade-in {
  animation: fade 250ms ease-in;
}